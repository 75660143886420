@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans&display=swap');
*{
    font-family: 'Poppins', sans-serif;
    /* user-select: none; */
    box-sizing: border-box;
    /* overflow-x: hidden; */
}

.custom_func{
    width: 100%
}

.custom_style2
{
    max-width: 1600px;
    margin: 0 auto;
}

.mobileNav
{
    position: absolute;
    top: 0;
    width: 50%;
    min-height: 100vh !important;
    overflow-y: scroll !important;
}

.responsive_navbar
{
    margin-right : 8rem !important;

}

@media(max-width : 1586px)
{
    .responsive_custom_nav
    {
        justify-content: center;
    }
    .responsive_navbar
    {
        margin-right : 6rem !important;

    }
}

@media(max-width : 1318px)
{
    .responsive_navbar 
    {
        margin-right : 5rem !important;
    }
}

@media(max-width : 1218px)
{
    .responsive_navbar 
    {
        margin-right : 3rem !important;
    }
}


@media(max-width : 1019px)
{
    .responsive_navbar 
    {
        margin-right : 2.5rem !important;
    }
}

@media(max-width : 979px)
{
    .responsive_navbar 
    {
        margin-right : 2rem !important;
    }
}
@media(max-width : 932px)
{
    .responsive_navbar 
    {
        margin-right : 1rem !important;
    }
}


@media(max-width : 557px)
{
    .rsponsive_fix 
    {
        justify-content: center;
        text-align: center;
        padding: 0 !important;
    }
    .rsponsive_fix  ul
    {
        padding-bottom: 0;
    }
    .rsponsive_fix_ul
    {
        padding-top: 0 !important;
        padding-bottom: 1rem !important;
    }
}

.galleryDiv 
{
   
    width: 100%;
}
.galleryDiv img{
    width: 100%;
   
   }
   .galleryDivEv {
     width: 80%;
   }
   .galleryDivEv img{
        margin: 0rem 1rem ;
        max-width: 85% !important;
   }
 
   .slick-arrow  
   {
    width: 100px !important; 
    
    height: 100px !important;
   }
  .slick-prev , .slick-next
  {
    font-size: 2rem !important;
  }
   .slick-prev:before, .slick-next:before
   {
    color: rgb(241, 242, 247) !important;
    font-size: 2rem !important;
    background-color: none !important;
    
   }
   .slick-arrow {
    z-index: 10 !important;
   }

   .z_index
   {
    z-index: 99999 !important;
    background-color: white;
   }

.custom_enhancement
{
    font-weight: bold;
    margin: 6px 0 ;
}

.rps_about_us_description:nth-child(2) , 
.rps_about_us_description:nth-child(3),
.rps_about_us_description:nth-child(4)
{
    font-weight: bold;
    margin-top: 1rem;
   
   
}

.rps_message:nth-child(7),
.rps_message:nth-child(8),
.rps_message:nth-child(9),
.rps_message:nth-child(10)

{
    margin-top: 1rem;
    text-align: center;
    color : rgb(30 58 138) ;
}
.rps_message:nth-child(10)
{
    background-color: aliceblue;
    padding: 1rem;
    
}

.rps_message_princ:nth-child(5)
{
    text-align: center;
    margin-top: 1rem;
    background-color: aliceblue;
    color : rgb(30 58 138) ;
}

.responsive_fix_heading 
{
    overflow: hidden;
}


.slick-slider
{
    overflow: hidden;
}

.loadingSpinner
{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
}
.facility_round
{
    border-radius: 50%;
   
    border: 8px solid orange;
}
.facility_round img 
{
    width: 100%;
}
.headingw
{
    border-bottom: 4px solid rgb(20, 38, 140);
    width: 10%;
    vertical-align: center;
    margin : 8px 0;
}
.galleryDivT
{
    width: 50%;

}

@media(max-width : 988px)
{
    .fix_card
    {
        width: 33% !important;

    }
    .galleryDivT
    {
        width: 80%;
    }
}

@media(max-width : 800px)
{
    .fix_card
    {
        width: 40% !important;
        margin-bottom: 1rem;
    }
    .fix_card h2
    {
        font-size: 1.5rem;
        margin: 1rem 0;
    }
}
@media(max-width : 650px)
{
    .responsive_fix_heading 
{
    overflow: hidden;
    text-align: center;
}

.galleryDivT
{
    width: 100%;
}


.address_fix
{
    text-align: center;
}

.fix_card
{
    width: 60% !important;
}
}

@media(max-width : 430px)
{
    .responsive_fix_heading 
{
    font-size: 2rem !important;
    text-align: center;
}
}

@media(max-width : 407px)
{
    .responsive_fix_heading 
{
    font-size: 1.75rem !important;
    text-align: center;
}
.address_fix
{
    font-size: 0.75rem;
    text-align: center;
}
}
@tailwind base;
@tailwind components;
@tailwind utilities;